import Box from "@mui/material/Box";
import {DataGrid, ruRU} from "@mui/x-data-grid";
import React, {useContext} from "react";
import {FilterContext, MapGridContext} from "../../pages/Map";
export function GridTable({rows, objectManager, apiRef, setSelectedObject, columns}){

    const handleRowClick = (params) => {
        setSelectedObject({
            coordinates: params.row.coordinates,
            id: params.id,
            regionObject: params.row.region,
            balloonContent: params.row.firstName,
            direction: params.row.direction,
            inn: params.row.inn
        })
        // const feature = objectManager.current.objects.getById(params.id);
        // if (feature) {
        //     objectManager.current.objects.balloon.open(params.id);
        // }
    }

    // const handleButtonClick = () => {
    //     // Найдем метку в ObjectManager и откроем ее балун
    //     const feature = objectManager.current.objects.getById(selectedObject.id);
    //     if (feature) {
    //         objectManager.current.objects.balloon.open(selectedObject.id);
    //     }
    // }

    return (
        <div className="">
            <Box sx={{ height: "calc(100vh - 177px)", width: '100vw', }}>
                <DataGrid
                    localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
                    rows={rows}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 10,
                            },
                        },
                    }}
                    apiRef={apiRef}
                    onRowClick={handleRowClick}
                    pageSizeOptions={[10]}
                    checkboxSelection={false}
                />
            </Box>
        </div>
    )
}
