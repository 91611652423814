import "../styles/adaptiveContacts.css"
export function Contacts() {
    return (
            <div className="parent">
                <div className="div4">
                    <div className="title_landing">Контакты</div>
                </div>
                <div className="div2">
                    <div className="hdr">Адрес:</div>
                    <p>109428, Москва г, Рязанский проспект, дом № 22, корпус 2</p>
                </div>
                <div className="div3">
                    <div className="mini_hdr">Связаться:</div>
                    <p>Телефон: <a href="tel:84991747724">+7 (499) 170-60-13</a></p>
                    <p>E-mail: <a href="mailto:niizhb@cstroy.ru">info@azhb.ru</a></p>
                </div>

                <div className="div1">
                    <iframe
                        title={"maps"}
                        src="https://yandex.ru/map-widget/v1/?lang=ru_RU&scroll=true&um=constructor%3Adfaf188e9acd650e6023c1a24926240ab04f9613d3214498304a7a668ed256ad"
                        allowFullScreen="true" width="100%" height="350" style={{display: "block", margin: 0}}>
                    </iframe>
                </div>
            </div>
    )
}
