import {YandexMap} from "../map/map";
import {GridTable} from "../grid/grid";
import React, {useContext, useEffect, useState} from "react";
import {MapGridContext} from "../../pages/Map";
import {useMediaQuery} from "react-responsive";
import {GridColumn} from "../../services/grid/grid.service";

export function ViewOfContent(){
    const {
        defaultState,
        handleObjectClick,
        mapManager,
        objectManager,
        objects,
        rows,
        setSelectedObject,
        apiRef,
        map,
        adaptiveRows,
        selectedObject
    } = useContext(MapGridContext);

    let isDesktopOrLaptop = useMediaQuery({ minWidth: 600 });
    let columns;
    if(isDesktopOrLaptop === true){
        columns = GridColumn.isDesktopOrLaptop;
    } else {
        columns = GridColumn.isTabletOrMobile
    }

    const [isReady, setIsReady] = useState(false);
    const [done, setIsDone] = useState(false);

    useEffect(() => {
        if(isReady && done === false) {
            if(objectManager !== null && objectManager.current !== null && selectedObject.id !== '') {
                objectManager?.current?.objects?.balloon?.open(selectedObject?.id)
            } else {
                if(!isReady) setIsReady(false);
            }
        } else {

        }

    }, [selectedObject, isReady, objectManager])



    return (<>
            {map && <YandexMap
                defaultState={defaultState}
                handleObjectClick={handleObjectClick}
                mapManager={mapManager}
                objectManager={objectManager}
                objects={objects}
                isReady={isReady}
                setIsReady={setIsReady}
                selectedObject={selectedObject}
            />}
            {!map && <GridTable rows={rows} columns={columns} objectManager={objectManager} setSelectedObject={setSelectedObject} apiRef={apiRef} adaptiveRows={adaptiveRows} />}
    </>
)
}
