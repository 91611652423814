import {Map, ObjectManager, TypeSelector, YMaps} from "@pbe/react-yandex-maps";
import React, {useEffect, useState} from "react";
import {InfinitySpin} from "react-loader-spinner";

export function YandexMap({defaultState, handleObjectClick, mapManager, objectManager, objects, selectedObject, isReady = false, setIsReady}){
    const myStyle = {
        width: "calc(100vw)",
        height: "calc(100vh - 177px + 50px)"
    }

    function start() {
        console.log('start')

        const markerId = 1;
        // if (mapManager.current) {
        //     const objectManager = mapManager.geoObjects.get(0);
        //     // Найти метку в объекте ObjectManager
        //     const feature = objectManager.getObjectById(markerId);
        //
        //     if (feature) {
        //         // Открыть балун для найденной метки
        //         feature.properties.balloonContent = 'Текст вашего балуна';
        //         feature.balloon.open();
        //     }
        // }

        if (selectedObject.id !== '') {
            console.log(objectManager)
            console.log(mapManager)
            console.log('inside', objectManager)
            objectManager?.current?.objects?.balloon?.open(selectedObject.id).then(r => {
                r?.balloon?.open(selectedObject.id)
            } )
        }
        console.log('end')
    }

    return (
        <>
            {!isReady &&
                (<div className="loader_map">
                    <InfinitySpin
                        width='300'
                        color="#4fa94d"
                    />
                </div>)
            }
            <YMaps>
                <Map
                    defaultState={defaultState}
                    style={myStyle}
                    instanceRef={mapManager}
                    onLoad={() => {
                        setIsReady(true);
                        // start();
                    }}

                >
                    <TypeSelector options={{float: 'left'}}/>
                    <ObjectManager
                        instanceRef={objectManager}
                        options={{
                            clusterize: true,
                            gridSize: 100
                        }}
                        clusters={{
                            groupByCoordinates: false,
                            clusterDisableClickZoom: true,
                            clusterHideIconOnBalloonOpen: false,
                            geoObjectHideIconOnBalloonOpen: false,
                        }}
                        onClick={handleObjectClick}
                        features={objects}
                        modules={[
                            "objectManager.addon.objectsBalloon",
                            "objectManager.addon.objectsHint",
                        ]}
                    />
                </Map>
            </YMaps>
        </>
    )
}
