import "./style.css"
import logo from "../../img/logo/currentLogo.png";
import {logoutUser} from "../../services/auth/auth.service";
import {NavLink} from "react-router-dom";
import {faCaretDown, faCaretUp} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useState} from "react";

export function Navbar({isAuth, setIsAuth}) {
    const enter = isAuth ? 'Выйти' : 'Вход';
    const [isClicked, setIsClicked] = useState(false);
    function myFunction() {
        let x = document.getElementById("myTopnav");
        if (x.className === "topnav") {
            x.className += " responsive";
        } else {
            x.className = "topnav";
        }
        setIsClicked(!isClicked);
    }

    return (
        <div className="container_navbar">
            <div className="logo_navbar">
                <a href="/">
                    <img src={logo} alt="" width={70}/>
                </a>
            </div>
            <div className="topnav" id="myTopnav">
                <a href="javascript:void(0);" className="fa_icon" onClick={() => myFunction()}>
                    <FontAwesomeIcon icon={isClicked ? faCaretUp : faCaretDown} />
                </a>

                {isAuth && <NavLink to="/profile" onClick={() => myFunction()}>
                    <p className='menu_item'>Личный кабинет</p>
                </NavLink>}

                <NavLink to="/login" onClick={() => {
                    setIsAuth(false);
                    logoutUser();
                    myFunction();
                }}>
                    <p className='menu_item' >{enter}</p>
                </NavLink>

                {!isAuth && <NavLink to="/register" onClick={() => myFunction()}>
                    <p className='menu_item'>Регистрация</p>
                </NavLink>}

                <NavLink to="/contacts" onClick={() => myFunction()}>
                    <p className='menu_item' >Контакты</p>
                </NavLink>

                <NavLink to="/" onClick={() => myFunction()}>
                    <p className='menu_item'>Карта</p>
                </NavLink>
            </div>
        </div>

    )


}
