import React, {useContext, useState} from "react";
import {FilterContext} from "../../../../pages/Map";

export function Comment(){
    const [isComment, setIsComment] = useState(false);
    const [comment, setComment] = useState('');
    const {
        setSelected,
        setError,
        setModalActive,
        selectedObject
    } = useContext(FilterContext);

    function closeCurrentBalloon() {
        let close = document.querySelector('ymaps[class$="-balloon__close-button"]');
        if (close != null) {
            close.click();
        }
    }

    function openCurrentBalloon() {
        let close = document.querySelector('ymaps[class$="-places-pane"]');
        console.log(close)
        if (close != null) {
            close.click();
        }
    }



    const handleSubmit = async () => {
        let message = comment + selectedObject.id + ' ' + selectedObject.balloonContent;
        let response = await fetch(`https://api.telegram.org/bot5686469491:AAGuWYRIfWvlPKU5i2hc3BBc-4JLX_KvaWM/sendMessage?chat_id=194897819&text={${message}}`,
            {
                method: 'POST',
                body: JSON.stringify(message),
                headers: {
                    'Content-Type': 'application/json'
                },
            });
        if (response.status === 200){
            setError('Данные отправлены на обработку');
            setModalActive(true)
        } else{
            setError('Ошибка отправки. Повторите позднее.');
            setModalActive(true)
        }
    }

    return (
        <>
            <button className="close_title" onClick={() => {setSelected(false); setIsComment(false); closeCurrentBalloon()}}>Закрыть</button>
            <button className="close_title" onClick={() => {setIsComment(true); openCurrentBalloon()}}>Комментарий</button>
            {isComment && (
                <div>
                    <input className="comment_input" type="text" onChange={e => setComment(e.target.value)} value={comment}/>
                    <button className="close_title" onClick={() => handleSubmit()}>Отправить</button>
                </div>

    )}
        </>)
}
