import React, {useContext, useEffect} from "react";
import {FilterContext} from "../../../pages/Map";
import {Comment} from "../components/comment/Comment";
import './style.css'
export function ObjectInfoDesktop(){
    const {
        selectedObject,
        objectManager
    } = useContext(FilterContext);

    return (
        <div className="wrapper">
            <strong className="object_title">
                <h1 className="h1_title">{selectedObject.balloonContent}</h1>
            </strong>
            <h2 className="h2_title"> <strong>Регион:</strong> {selectedObject.regionObject.split('-')[1]}</h2>
            <h2 className="h2_title"> <strong>Направление:</strong> {selectedObject.direction}</h2>
            <h2 className="h2_title"> <strong>Координаты объекта:</strong></h2>
            <h2 className="h2_title">{selectedObject.coordinates[0]}     {selectedObject.coordinates[1]}</h2>
            <h2 className="h2_title"><strong>ИНН:</strong> {selectedObject.inn}</h2>
            <div style={{display: "flex", flexDirection: "column", bottom: '0', position: "relative"}}>
                <Comment  selectedObject={selectedObject} />
            </div>

        </div>
    )
}
