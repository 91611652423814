import React, {useContext, useState} from "react";
import {FilterContext} from "../../../pages/Map";
import './style.css'
import {faChevronDown, faXmark} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {MobileComment} from "../components/mobile-comment/MobileComment";

export function ObjectInfoMobile(){

    const {
        selectedObject,
        setSelectedObject,
        setSelected,
    } = useContext(FilterContext);

    const [rotation, setRotation] = useState(180);
    const [height, setHeight] = useState('120px');
    const [isComment, setIsComment] = useState(false);
    const handleMoreClick = (e) => {
        if(rotation === 180){
            setRotation(0);
            setHeight('135px');
        } else {
            setRotation(180);
            setHeight('120px');
            setIsComment(false);
        }
    };

    const handleSetComment = (e) => {
        if(!isComment) {
            setHeight('210px');
            setIsComment(true)
        }
    };

    return (
        <div className="wrapper" style={{height: height}}>
            <FontAwesomeIcon className="icon" icon={faChevronDown} rotation={rotation} onClick={handleMoreClick} />
            <FontAwesomeIcon className="icon_close" icon={faXmark} onClick={() => {
                setSelectedObject(
                    {
                        coordinates: '',
                        id: '',
                        regionObject: '',
                        direction: '',
                        balloonContent: '',
                        inn: '',
                    }
                );
                setSelected(false)
            }
            } />
           <strong><p className="naming">{selectedObject.balloonContent}</p></strong>
            <div>
                {selectedObject.regionObject.split('-')[1]}
            </div>
            <div>
                {selectedObject.direction}
            </div>
            <div>
                <strong>Координаты: </strong>
                {selectedObject.coordinates[0]}     {selectedObject.coordinates[1]}
            </div>
            {rotation === 0 && !isComment && (
                <div onClick={handleSetComment} >
                    <p>
                        Пожаловаться
                    </p>
                </div>
            )}

            {isComment && (
                <div>
                    <MobileComment setIsComment={setIsComment} setRotation={setRotation} setHeight={setHeight} />
                </div>
            )}
        </div>
    )
}
