
export class GridColumn {
    static isTabletOrMobile = [
                { field: 'id', headerName: 'ID', editable: true, width: 40, minWidth: 40},
                {
                    field: 'firstName',
                    headerName: 'Название',
                    editable: false,
                    sortable: true,
                    width: 150,
                },
                {
                    field: 'direction',
                    headerName: 'Направление',
                    editable: false,
                    sortable: true,
                    width: 130,
                },
                {
                    field: 'region',
                    headerName: 'Регион',
                    type: 'string',
                    editable: false,
                    sortable: true,
                    width: 150,
                },
                {
                    field: 'inn',
                    headerName: 'ИНН',
                    editable: false,
                    sortable: true,
                    width: 110,
                },
                {
                    field: 'ocved',
                    headerName: 'ОКВЭД',
                    editable: false,
                    sortable: true,
                    width: 70,
                },
                {
                    field: 'address',
                    headerName: 'Адрес',
                    editable: false,
                    sortable: true,
                    width: 150,
                },
                {
                    field: 'main',
                    headerName: 'Руководитель',
                    editable: false,
                    sortable: true,
                    width: 150,
                },
                {
                    field: 'phone',
                    headerName: 'Телефон',
                    editable: false,
                    sortable: true,
                    width: 150,
                },
                {
                    field: 'email',
                    headerName: 'Почта',
                    editable: false,
                    sortable: true,
                    width: 150,
                },
                {
                    field: 'coordinates',
                    headerName: 'Координаты',
                    editable: false,
                    sortable: true,
                    width: 150,
                },
            ];

        static isDesktopOrLaptop = [
            { field: 'id', headerName: 'ID', editable: true, width: 40, minWidth: 40},
            {
                field: 'firstName',
                headerName: 'Название',
                editable: false,
                sortable: true,
                flex: 1,
            },
            {
                field: 'direction',
                headerName: 'Направление',
                editable: false,
                sortable: true,
                flex: 1,
            },
            {
                field: 'region',
                headerName: 'Регион',
                type: 'string',
                editable: false,
                sortable: true,
                flex: 1,
            },
            {
                field: 'inn',
                headerName: 'ИНН',
                editable: false,
                sortable: true,
                flex: 1,
            },
            {
                field: 'ocved',
                headerName: 'ОКВЭД',
                editable: false,
                sortable: true,
                flex: 1,
            },
            {
                field: 'address',
                headerName: 'Адрес',
                editable: false,
                sortable: true,
                flex: 1,
            },
            {
                field: 'main',
                headerName: 'Руководитель',
                editable: false,
                sortable: true,
                flex: 1,
            },
            {
                field: 'phone',
                headerName: 'Телефон',
                editable: false,
                sortable: true,
                flex: 1,
            },
            {
                field: 'email',
                headerName: 'Почта',
                editable: false,
                sortable: true,
                flex: 1,
            },
            {
                field: 'coordinates',
                headerName: 'Координаты',
                editable: false,
                sortable: true,
                flex: 1,
            },
        ];
}
