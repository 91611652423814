import './style.css'
import React, {useContext, useState} from "react";
import {FilterContext} from "../../pages/Map";
import {ButtonCategory} from "../buttonOfCategory/ButtonCategory";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCaretLeft, faCaretRight,} from "@fortawesome/free-solid-svg-icons";
export function Filter2({setTotalizer, totalizer}){
    const [isActive, setIsActive] = useState(false);

    const {
        objects,
        region,
        category,
        setMap,
        map,
        selectedObject,
        setSelected,
        setDefaultState,
        objectManager,
        setTotal,
    } = useContext(FilterContext);

    function sortArrayByFirstTwoDigits(arr) {
        arr.sort((a, b) => {
            const firstTwoDigitsA = parseInt(a.toString().substring(0, 2));
            const firstTwoDigitsB = parseInt(b.toString().substring(0, 2));
            return firstTwoDigitsA - firstTwoDigitsB;
        });
        return arr;
    }
    let reg = new Set(region)
    let cat = new Set(category)
    reg = Array.from(reg)
    cat = Array.from(cat)
    let regDefault = sortArrayByFirstTwoDigits(reg)
    let catDefault = sortArrayByFirstTwoDigits(cat)
    // let regForMap = [];
    const [regForMap, setRegForMap] = useState([]);

    let catForMap = [];
    const [dropColor, setDropColor] = useState(false);

    const [isClicked, setIsClicked] = useState(true);

    reg = regDefault.map(
        (reg) => <ButtonCategory
            key={'unique'+reg}
            objects={objects}
            id={'dynamic-'+reg}
            objectManager={objectManager}
            catForMap={catForMap}
            regForMap={regForMap}
            setRegForMap={setRegForMap}
            dropColor={dropColor}
            setDropColor={setDropColor}
            typ={1}
            setTotal={setTotal}
            totalizer={totalizer}
            setTotalizer={setTotalizer}
        >
            {reg}
        </ButtonCategory>
    )

    cat = catDefault.map(
        (cat) => <ButtonCategory
            key={'unique'+cat}
            objects={objects}
            id={'dynamic-'+cat}
            objectManager={objectManager}
            catForMap={catForMap}
            regForMap={regForMap}
            setRegForMap={setRegForMap}
            dropColor={dropColor}
            setDropColor={setDropColor}
            typ={2}
            setTotal={setTotal}
            totalizer={totalizer}
            setTotalizer={setTotalizer}
        >
            {cat}
        </ButtonCategory>
    )
    const handleClick = (e) => {
        e.preventDefault();
        setIsClicked(!isClicked);
        setIsActive(!isActive);
    }

    return (
        <>
            <div className={!isActive ? 'menu' : 'menu menu_active'}>
                <FontAwesomeIcon icon={isClicked ? faCaretLeft : faCaretRight} className="menu-btn" onClick={handleClick} />
                <nav className="menu-list">
                    {map && (<div>
                        <div className="filter_container_cat">
                            <p><strong>Категории</strong></p>
                            <div className="">
                                {cat}
                            </div>
                        </div>
                        <div>
                            <p><strong>Регион и субъект</strong></p>
                            <div className="region">
                                {reg}
                            </div>
                        </div>
                    </div>)}
                    <div className="filter_features_button">
                        <div>
                            <div className="close_filter" style={{marginBottom: '7px'}} onClick={() => {
                                if(selectedObject && !map){
                                    // objectManager.current.objects.balloon.open(selectedObject?.id);
                                    setSelected(true)
                                    setDefaultState({
                                        center: selectedObject.coordinates ? selectedObject.coordinates : [61.775181, 81.635519],
                                        zoom:  selectedObject.coordinates ? 12 : 4,
                                    })
                                } else {
                                    setSelected(false)
                                }
                                setMap(!map);
                            }}>
                                {map ? 'Таблица' : 'Карта'}
                            </div>
                        </div>
                        <div className="close_filter"  onClick={() => {
                            objectManager?.current?.setFilter();
                            setDropColor(true);
                            setRegForMap([]);
                            catForMap = [];
                            setTotalizer([['Заводы ЖБИ', 0],
                                ['Производство стальной арматуры', 0],
                                ['Производство заполнителей для легкого бетона', 0],
                                ['Производство цемента', 0],
                                ['Производство заполнителей для тяжелого бетона', 0],
                                ['Производство товарного бетона', 0],
                                ['Производство композитной арматуры', 0]]);
                        }}>
                            Сбросить фильтр
                        </div>
                    </div>
                </nav>
            </div></>

    )
}
