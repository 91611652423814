import {useEffect, useState} from "react";

export function ButtonCategory(
    {children,
        id,
        objectManager,
        regForMap,
        setRegForMap,
        catForMap,
        dropColor,
        typ,
        setDropColor,
        setTotal,
        setTotalizer,
        totalizer,
    }){
    const [isClicked, setIsClicked] = useState(false);

    useEffect(() => {
        if(dropColor) {
            setIsClicked(false);
            setDropColor(false);
        }
    }, [dropColor]);

    function increaser() {
        objectManager.current.objects.each(function (object) {
            if (regForMap.includes(object?.properties?.region)){
                switch (object?.properties?.direction) {
                    case 'Заводы ЖБИ':
                        totalizer[0][1] += 1;
                        setTotalizer(totalizer);
                        break;
                    case 'Производство стальной арматуры':
                        totalizer[1][1] += 1;
                        setTotalizer(totalizer);
                        break;
                    case 'Производство заполнителей для легкого бетона':
                        totalizer[2][1] += 1;
                        setTotalizer(totalizer);
                        break;
                    case 'Производство цемента':
                        totalizer[3][1] += 1;
                        setTotalizer(totalizer);
                        break;
                    case 'Производство заполнителей для тяжелого бетона':
                        totalizer[4][1] += 1;
                        setTotalizer(totalizer);
                        break;
                    case 'Производство товарного бетона':
                        totalizer[5][1] += 1;
                        setTotalizer(totalizer);
                        break;
                    case 'Производство композитной арматуры':
                        totalizer[6][1] += 1;
                        setTotalizer(totalizer);
                        break;
                    default:
                        console.log('error!')
                }
            }
        })
    }

    function decreaser() {
        objectManager.current.objects.each(function (object) {
            if (regForMap.includes(object?.properties?.region)){
                switch (object?.properties?.direction) {
                    case 'Заводы ЖБИ':
                        totalizer[0][1] -= 1;
                        setTotalizer(totalizer);
                        break;
                    case 'Производство стальной арматуры':
                        totalizer[1][1] -= 1;
                        setTotalizer(totalizer);
                        break;
                    case 'Производство заполнителей для легкого бетона':
                        totalizer[2][1] -= 1;
                        setTotalizer(totalizer);
                        break;
                    case 'Производство цемента':
                        totalizer[3][1] -= 1;
                        setTotalizer(totalizer);
                        break;
                    case 'Производство заполнителей для тяжелого бетона':
                        totalizer[4][1] -= 1;
                        setTotalizer(totalizer);
                        break;
                    case 'Производство товарного бетона':
                        totalizer[5][1] -= 1;
                        setTotalizer(totalizer);
                        break;
                    case 'Производство композитной арматуры':
                        totalizer[6][1] -= 1;
                        setTotalizer(totalizer)
                        break;
                    default:
                        console.log('error!')
                }
            }
        })
    }

    function defaultCheckerTotal() {
        if(regForMap.length === 0) {
            setTotalizer([['Заводы ЖБИ', 0],
                ['Производство стальной арматуры', 0],
                ['Производство заполнителей для легкого бетона', 0],
                ['Производство цемента', 0],
                ['Производство заполнителей для тяжелого бетона', 0],
                ['Производство товарного бетона', 0],
                ['Производство композитной арматуры', 0]])
        }
        setTotal('its not available string');
    }

    function reRender (prop) {
        if(typ === 1) {
            if(regForMap.includes(prop)){
                decreaser();
                defaultCheckerTotal();
                for(let i = 0; i < regForMap.length; i++) {
                    if(prop === regForMap[i]) {
                        regForMap.splice(i, 1);
                    }
                }
            } else  {
                regForMap.push(prop);
                increaser();
                defaultCheckerTotal();
            }
            setRegForMap(regForMap);
            setTotal(prop + Math.random());
        }
        if (typ === 2){
            if(catForMap.includes(prop)){
                for(let j = 0; j < catForMap.length; j++) {
                    if(prop === catForMap[j]) {
                        catForMap.splice(j, 1)
                    }
                }
            } else {
                catForMap.push(prop)
            }
        }

        if(regForMap.length === 0 && catForMap.length === 0) {
            objectManager?.current?.setFilter()
        }
        if(regForMap.length !== 0 && catForMap.length !== 0) {
            objectManager?.current?.setFilter(function(object) {
                let bool = true;
                bool = bool && regForMap.includes(object.properties.region);
                bool = bool && catForMap.includes(object.properties.direction);
                return bool
            })
            return
        }
        if (catForMap.length !== 0){
            objectManager?.current?.setFilter(function(object) {
                return catForMap.includes(object.properties.direction)
            })
        }
        if (regForMap.length !== 0){
            objectManager?.current?.setFilter(function(object) {
                return regForMap.includes(object.properties.region)
            })
        }
    }


    return (
        <div
            id={id}
            onClick={() => {
                setIsClicked(!isClicked);
                reRender(children);
            }}
            style={{color: isClicked ? 'green' : 'black'}}>
            {children}
        </div>
    )

}
