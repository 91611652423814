import '../styles/map.css'
import React, {createContext, useEffect, useState} from "react";
// import data from '../yandex/BigDataYandex.json'
import {useGridApiRef} from '@mui/x-data-grid';
import {ViewOfContent} from "../components/mapGridView/ViewOfContent";
import {ObjectInfoDesktop} from "../components/object-info/objectInfoDesktop/ObjectInfoDesktop";
import {Counters} from "../components/counters/counter";
import {Toaster} from "react-hot-toast";
import {Filter2} from "../components/adaptiveFilter/Filter";
import {useMediaQuery} from "react-responsive";
import {ObjectInfoMobile} from "../components/object-info/objectInfoMobile/ObjectInfoMobile";


export const MapGridContext = createContext(undefined);
export const FilterContext = createContext(undefined);
export function Map2({isAuth, user, data}) {

    const objectManager = React.createRef();
    const mapManager = React.createRef();

    const [total, setTotal] = useState(
        [['Заводы ЖБИ', 5471],
            ['Производство стальной арматуры', 24],
            ['Производство заполнителей для легкого бетона', 37],
            ['Производство цемента', 16],
            ['Производство заполнителей для тяжелого бетона', 321],
            ['Производство товарного бетона', 2432],
            ['Производство композитной арматуры', 12]]
    );

    const [totalizer, setTotalizer] = useState(
        [
            ['Заводы ЖБИ', 0],
            ['Производство стальной арматуры', 0],
            ['Производство заполнителей для легкого бетона', 0],
            ['Производство цемента', 0],
            ['Производство заполнителей для тяжелого бетона', 0],
            ['Производство товарного бетона', 0],
            ['Производство композитной арматуры', 0]
        ]
    );

    const [done, setDone] = useState(false);

    useEffect(() => {
        if(!data && done === false) {
            setDone(!done);
        } else {
            const obj = JSON.parse(data);
            setObject(obj);
            setDone(false);
            setReload(true);
        }
    }, [isAuth,user, setTotal, done])



    let rows = [];
    let adaptiveRows = [];
    const isDesktopOrLaptop = useMediaQuery({ minWidth: 600 });
    const [map, setMap] = useState(true);
    const [selectedObject, setSelectedObject] = useState({
        coordinates: '',
        id: '',
        regionObject: '',
        direction: '',
        balloonContent: '',
        inn: '',
    });
    const [selected, setSelected] = useState(false);
    let objects = [];
    let region = [];
    let category = [];
    let apiRef = useGridApiRef();


    const [defaultState, setDefaultState] = useState({
        center: [61.775181, 81.635519],
        zoom: 4,
    });

    const [object, setObject] = useState(null);

    if(object) {
        object.forEach((item) => {
            region.push(item.properties.region)
            category.push(item.properties.direction)
            objects.push(
                {
                    type: 'Feature',
                    id: item.id,
                    geometry: {
                        type: 'Point',
                        coordinates: item.geometry.coordinates
                    },
                    properties:{
                        region: item.properties.region,
                        direction: item.properties.direction,
                        balloonContentBody: item.properties.balloonContentBody
                    },
                    options: {iconColor: item.options.iconColor
                    }
                }
            )
            rows.push({id: item.id,
                firstName: item.properties.balloonContentBody,
                direction: item.properties.direction,
                region: item.properties.region,
                inn: item.properties.inn,
                ocved: item.properties.ocved,
                address: item.properties.address,
                main: item.properties.main,
                phone: item.properties.phone,
                email: item.properties.email,
                coordinates: item.geometry.coordinates})
            adaptiveRows.push([item.id,
                item.properties.balloonContentBody,
                item.properties.direction,
                item.properties.region,
                item.properties.inn,
                item.properties.ocved,
                item.properties.address,
                item.properties.main,
                item.properties.phone,
                item.properties.email,
                item.geometry.coordinates
            ])
        })
    }







    if(!isAuth && rows.length > 4) {
        rows = rows.slice(0, 5)
    }
    const [reload, setReload] = useState(true);




    const handleObjectClick = (e) => {
        e.preventDefault();
        const objectId = e.get('objectId');
        const obj = object.find(obj => obj.id === objectId);

        if (obj) {
            let coordinates = obj.geometry.coordinates;
            let id = obj.id;
            let regionObject = obj.properties.region;
            let direction = obj.properties.direction;
            let balloonContent = obj.properties.balloonContentBody;
            let inn = obj.properties.inn;
            setSelectedObject({coordinates: coordinates, id: id, regionObject: regionObject, balloonContent: balloonContent, direction: direction, inn: inn});
            setSelected(true);
        }
    };

    return(
        <>
            <div className="container_for_features">
                <div className="container_for_map" >
                    <MapGridContext.Provider className="Provider" value={{
                        defaultState,
                        handleObjectClick,
                        mapManager,
                        map,
                        objectManager,
                        objects,
                        rows,
                        setSelectedObject,
                        apiRef,
                        adaptiveRows, selectedObject
                    }} >
                        <ViewOfContent />
                    </MapGridContext.Provider>
                </div>
                <FilterContext.Provider value={{
                    objects,
                    region,
                    category,
                    setMap,
                    map,
                    selectedObject,
                    setSelected,
                    setSelectedObject,
                    setDefaultState,
                    objectManager,
                    setTotal,
                }}>
                    <div className="container_for_map_info">
                        <Counters totalizer={totalizer}/>
                    </div>
                    <Filter2 totalizer={totalizer} setTotalizer={setTotalizer}/>
                    {selected && isDesktopOrLaptop && (
                        <div className="container_for_object_info_desktop">
                            <ObjectInfoDesktop />
                        </div>
                    )}
                    {selected && !isDesktopOrLaptop && (
                        <div className="container_for_object_info_mobile">
                            <ObjectInfoMobile />
                        </div>
                    )}
                </FilterContext.Provider>
                <Toaster
                    position="top-center"
                    reverseOrder={false}
                    gutter={8}
                    containerClassName=""
                    containerStyle={{}}
                    toastOptions={{
                        className: '',
                        duration: 5000,
                        style: {
                            background: '#363636',
                            color: '#fff',
                        },
                        success: {
                            duration: 3000,
                            theme: {
                                primary: 'green',
                                secondary: 'black',
                            },
                        },
                    }}
                />
            </div>
        </>
    )
}
