import './App.css';
import "./styles/main.css";
import React, {useEffect, useState} from "react";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import {Navbar} from "./components/adaptiveNavbar/Navbar";
import {Map2} from './pages/Map'
import {Footer} from "./components/footer/Footer";
import {Register} from "./pages/Register";
import {Login} from "./pages/Login";
import {Profile} from "./pages/Profile";
import {AddNewBallon} from "./pages/AddNewBallon";
import {Contacts} from "./pages/Contacts";
import {getUser} from "./services/auth/auth.service";
import axios from "axios";
import toast from "react-hot-toast";

function App() {
    const [first, setFirst] = useState(false);
    const [isAuth, setIsAuth] = useState(false);
    const [user, setUser] = useState({
        "id": 100000,
        "email": "",
        "isConfirmed": 0,
        "phone": "",
        "fullName": "",
        "company": ""
    });

    async function getData() {
        return await axios.get(
            'https://betindustry.ru:4000/api/download',
        )
    }

    const [data, setData] = useState(null);


    useEffect(() => {
        getData().then(value => {
            setData(value.data.fileContent);
        })

        if(!first){
            if(localStorage.getItem('token')){
                const res = getUser();
                res.then(data => {
                    setIsAuth(true);
                    setUser(data?.data?.decoded);
                    setFirst(true)
                })
                localStorage.setItem('auth', 'true');
            }
            if(localStorage.getItem('auth') === 'false'){
                if(data) {
                    if(!isAuth && user.id === 100000){
                        toast.error('Для получения полного доступа зарегистрируйтесь и подтвердите учетную запись в АЖБ', {
                            style: {
                                minWidth: '300px',
                            },
                        });
                    } else {
                        toast.success('Подтвержденная учетная запись АЖБ', {
                            style: {
                                minWidth: '300px',
                            },
                        });
                }
                }
            }
        }

    }, [first, isAuth, user,data ])
    return (
    <div className="App">
        <Router>
            <Navbar isAuth={isAuth} setIsAuth={setIsAuth}/>
            <Routes>
                <Route path="/" element={<Map2 isAuth={isAuth} user={user} data={data} />} />
                <Route path="/register" element={<Register isAuth={isAuth} setIsAuth={setIsAuth} />} />
                <Route path="/login" element={<Login isAuth={isAuth} setIsAuth={setIsAuth} setUser={setUser}/>} />
                <Route path="/profile" element={<Profile isAuth={isAuth} setIsAuth={setIsAuth} user={user} />} />
                <Route path="/add-new-ballon" element={<AddNewBallon isAuth={isAuth} setIsAuth={setIsAuth} />} />
                <Route path="/contacts" element={<Contacts isAuth={isAuth} setIsAuth={setIsAuth} />} />
            </Routes>
            <Footer />
        </Router>
    </div>
    );
}

export default App;
