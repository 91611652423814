import React, {useEffect} from "react";
import './style.css'

export function Counters({totalizer}){
    const colors = [
        '#B00000', '#480607', '#960018', '#B00000', '#480607', '#960018', '#B00000'
    ]

    let sum = 0;

    useEffect(() => {

    }, [colors, totalizer])


    totalizer.forEach((total) => {
        sum += total[1];
    })


    return (
        (totalizer && <div className="counter_wrapper">
            <div className="counter_item" style={{backgroundColor: colors[0]}}>
                <p className="cat_prop_p_number"><strong>{totalizer[0][1] === 0 && sum === 0 ? '5471' : totalizer[0][1]}</strong></p>
                <p className="cat_prop_p">{totalizer[0][0]}</p>
            </div>
            <div className="counter_item" style={{backgroundColor: colors[1]}}>
                <p className="cat_prop_p_number"><strong>{totalizer[1][1] === 0 && sum === 0 ? '24' : totalizer[1][1]}</strong></p>
                <p className="cat_prop_p">{totalizer[1][0]}</p>
            </div>
            <div className="counter_item" style={{backgroundColor: colors[2]}}>
                <p className="cat_prop_p_number"><strong>{totalizer[2][1] === 0 && sum === 0 ? '37' : totalizer[2][1]}</strong></p>
                <p className="cat_prop_p">{totalizer[2][0]}</p>
            </div>
            <div className="counter_item" style={{backgroundColor: colors[3]}}>
                <p className="cat_prop_p_number"><strong>{totalizer[3][1] === 0 && sum === 0 ? '16' : totalizer[3][1]}</strong></p>
                <p className="cat_prop_p">{totalizer[3][0]}</p>
            </div>
            <div className="counter_item" style={{backgroundColor: colors[4]}}>
                <p className="cat_prop_p_number"><strong>{totalizer[4][1] === 0 && sum === 0 ? '321' : totalizer[4][1]}</strong></p>
                <p className="cat_prop_p">{totalizer[4][0]}</p>
            </div>
            <div className="counter_item" style={{backgroundColor: colors[5]}}>
                <p className="cat_prop_p_number"><strong>{totalizer[5][1] === 0 && sum === 0 ? '2432' : totalizer[5][1]}</strong></p>
                <p className="cat_prop_p">{totalizer[5][0]}</p>
            </div>
            <div className="counter_item" style={{backgroundColor: colors[6]}}>
                <p className="cat_prop_p_number"><strong>{totalizer[6][1] === 0 && sum === 0 ? '12' : totalizer[6][1]}</strong></p>
                <p className="cat_prop_p">{totalizer[6][0]}</p>
            </div>
        </div>)
    )
}
