import React, {useContext, useState} from "react";
import {FilterContext} from "../../../../pages/Map";
import toast, {Toaster} from "react-hot-toast";

export function MobileComment({setIsComment, setRotation, setHeight}){
    const [comment, setComment] = useState('');

    const {
        selectedObject
    } = useContext(FilterContext);

    const handleSubmit = async () => {
        let message = comment + selectedObject.id + ' ' + selectedObject.balloonContent;
        let response = await fetch(`https://api.telegram.org/bot5686469491:AAGuWYRIfWvlPKU5i2hc3BBc-4JLX_KvaWM/sendMessage?chat_id=194897819&text={${message}}`,
            {
                method: 'POST',
                body: JSON.stringify(message),
                headers: {
                    'Content-Type': 'application/json'
                },
            });
        if (response.status === 200){
            toast.success('Данные отправлены на обработку', {
                style: {
                    minWidth: '300px',
                },
            });
            setIsComment(false);
            setRotation(180);
            setHeight('120px');
        } else{
            toast.error('Ошибка', {
                style: {
                    minWidth: '300px',
                },
            });
        }
    }

    return (
        <div>
            <input className="comment_input" type="text" onChange={e => setComment(e.target.value)} value={comment}/>
            <button className="close_title" onClick={() => handleSubmit()}>Отправить</button>
            <Toaster
                position="top-center"
                reverseOrder={false}
                gutter={8}
                containerClassName=""
                containerStyle={{}}
                toastOptions={{
                    className: '',
                    duration: 5000,
                    style: {
                        background: '#363636',
                        color: '#fff',
                    },
                    success: {
                        duration: 3000,
                        theme: {
                            primary: 'green',
                            secondary: 'black',
                        },
                    },
                }}
            />
        </div>
    )
}
